import React from "react"

import { Link } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"

import { auth, Firebase } from "../../firebase"
import { useTranslations } from "../../utils/intl"
import {
  bpSm,
  ButtonLink,
  ContentWrapper,
  ContentWrapperDiv,
  H1,
  P,
  shevy,
  theme,
  toEm,
} from "../index"

export const HeroBlockSmall: React.FC = props => {
  const { it } = useTranslations()
  const [user, setUser] = React.useState<Firebase.User | null>(null)

  React.useEffect(
    () =>
      auth.onAuthStateChanged(user => {
        setUser(user)
      }),
    []
  )
  return (
    <ContentWrapper
      css={{
        paddingTop: toEm(89, 12),
        [bpSm]: { paddingTop: shevy.baseSpacing() },
      }}
      {...props}
    >
      <Helmet
        meta={[
          {
            name: "apple-itunes-app",
            content: "app-id=940383157",
          },
        ]}
      />
      <img
        src={require("../../svg/runbit-logo.svg").default}
        css={{
          marginBottom: shevy.baseSpacing(2),
          width: toEm(72 * 1.5),
          borderRadius: toEm(12),
        }}
      />
      <H1 css={{ letterSpacing: -2 }}>
        {it([
          "Turn your city into a fun game!",
          "Förvandla din stad till ett spel",
        ])}
      </H1>
      <P css={{ fontWeight: 500 }}>
        {it([
          "Walk, Jog, Run and explore more, your city turns into a fun game!",
          "Gå, jogga eller spring! Runbit förvandlar din stad till ett spel.",
        ])}
      </P>

      {user ? (
        <ButtonLink
          to="/logged-in"
          variant="filled"
          color={theme.colors.black}
          css={{ fontSize: "1em" }}
        >
          {it(["Profile", "Profil"])}
        </ButtonLink>
      ) : (
        <ButtonLink
          to="/login"
          variant="filled"
          color={theme.colors.black}
          css={{ fontSize: "1em" }}
        >
          {it(["Log in", "Logga in"])}
        </ButtonLink>
      )}
    </ContentWrapper>
  )
}

const Header: React.FC = props => {
  const { it } = useTranslations()

  const [user, setUser] = React.useState<Firebase.User | null>(null)

  React.useEffect(
    () =>
      auth.onAuthStateChanged(user => {
        setUser(user)
      }),
    []
  )

  return (
    <nav
      css={{
        backgroundColor: "#ffffff",
        boxShadow: `0px ${toEm(3)} ${toEm(6)} rgba(0,0,0,0.16)`,
      }}
      {...props}
    >
      <ContentWrapperDiv
        css={{
          display: "flex",
          alignItems: "center",
          paddingTop: toEm(24),
          paddingBottom: toEm(24),
        }}
      >
        <Link to="/" css={{ lineHeight: 0 }}>
          <img
            src={require("../../svg/runbit-logo.svg").default}
            css={{ width: toEm(54), borderRadius: toEm(5) }}
          />
        </Link>
        <ul
          css={{
            flexGrow: 1,
            listStyle: "none",
            li: { display: "inline-block", fontWeight: 600 },
            "li + li": { marginLeft: toEm(32) },
            ["@media(max-width: 876px)"]: { li: { display: "none" } },
          }}
        >
          {[
            { title: it(["About", "Förklaring"]), to: "/#about" },
            { title: it(["Schools", "Skolor"]), to: "/school" },
            { title: it(["Pricing", "Kostnad"]), to: "/#pricing" },
            { title: it(["Contact us", "Kontakta oss"]), to: "/#contact" },
          ].map(({ title, to }) => {
            return (
              <li key={to}>
                <Link
                  css={{
                    color: theme.colors.black,
                    textDecoration: "none",
                  }}
                  to={to}
                >
                  {title}
                </Link>
              </li>
            )
          })}
        </ul>
        {user ? (
          <ButtonLink
            to="/logged-in"
            variant="filled"
            color={theme.colors.red}
            css={{ fontSize: "1em", color: "white" }}
          >
            {it(["Profile", "Profil"])}
          </ButtonLink>
        ) : (
          <ButtonLink
            to="/login"
            variant="filled"
            color={theme.colors.red}
            css={{ fontSize: "1em", color: "white" }}
          >
            {it(["Log in", "Logga in"])}
          </ButtonLink>
        )}
      </ContentWrapperDiv>
    </nav>
  )
}

export default Header
